/**
 * 帮助
 * @type {{title: string, content: string}[]}
 */
const helpData = [
  {
    title: "如何保持账户安全？",
    type: "text",
    content: [
      "在操作完成后点击右上角图标主动退出系统。",
      "定期更换密码，密码应包含大写字母和特殊符号。"
    ]
  },
  {
    title: "数据没有加载出来怎么办？",
    type: "text",
    content: [
      "这种情况一般是网络原因，也可能是服务器在维护造成。刷新重试即可。"
    ]
  },
  {
    title: "不能删除腕表数据？",
    type: "text",
    content: [
      "当腕表数据被用户绑定时，不能删除该腕表数据。只有当没有任何用户绑定时这个腕表数据才可以删除。"
    ]
  },
  {
    title: "误点禁用/启用数据怎么办？",
    type: "text",
    content: [
      "对敏感操作我们都进行了提示确认，且大部分操作都是可逆的。",
      "比如你不小心禁用了一条数据，可以再改变为启用状态。"
    ]
  },
  {
    title: "操作没有响应？",
    type: "text",
    content: ["有可能是网络延迟，可尝试刷新页面进行重试。"]
  }
];
export { helpData };
